import React from 'react';
import { Ship } from 'lucide-react';

const Logo = () => {
  return (
    <div className="flex items-center">
      <Ship size={40} className="text-blue-600 mr-3" />
      <span className="text-3xl font-bold text-gray-800 dark:text-blue-600 font-header tracking-wide uppercase">FerryTimer</span>
    </div>
  );
};

export default Logo;
