import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { Sun, Moon, Settings, RefreshCw, RefreshCcwDotIcon } from 'lucide-react';
import { ferrySchedule } from './data/ferrySchedule';
import { FerryCard } from './components/FerryCard';
import { SettingsModal } from './components/SettingsModal';
import { useSettings } from './context/SettingsContext';
import Logo from './components/Logo';
import { v4 as uuidv4 } from 'uuid';

// Theme context
const ThemeContext = createContext();

// Theme provider component
const ThemeProvider = ({ children }) => {
  const { settings } = useSettings();
  const [darkMode, setDarkMode] = useState(() => {
    if (settings.useSystemTheme) {
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
    return false;
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      if (settings.useSystemTheme) {
        setDarkMode(e.matches);
      }
    };

    if (settings.useSystemTheme) {
      mediaQuery.addEventListener('change', handleChange);
    }

    return () => mediaQuery.removeEventListener('change', handleChange);
  }, [settings.useSystemTheme]);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Hook to use theme
const useTheme = () => useContext(ThemeContext);

// Theme toggle button
const ThemeToggle = () => {
  const { darkMode, setDarkMode } = useTheme();
  const { settings, updateSettings } = useSettings();

  const handleToggle = () => {
    if (settings.useSystemTheme) {
      updateSettings({ useSystemTheme: false });
    }
    setDarkMode(!darkMode);
  };

  return (
    <button
      onClick={handleToggle}
      className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 transition-colors duration-300"
    >
      {darkMode ? <Sun size={20} /> : <Moon size={20} />}
    </button>
  );
};

const App = () => {
  const [pinnedRoutes, setPinnedRoutes] = useState(() => {
    const saved = localStorage.getItem('pinnedRoutes');
    return saved ? JSON.parse(saved) : [];
  });

  const [userLocation, setUserLocation] = useState(null);
  const [sortedFerries, setSortedFerries] = useState([]);
  const [customAddress, setCustomAddress] = useState('');
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const { settings } = useSettings() || { settings: {} };
  const [orderKey, setOrderKey] = useState(uuidv4());
  const [needsRefresh, setNeedsRefresh] = useState(false);
  // const sortTimeoutRef = useRef(null);

  useEffect(() => {
    localStorage.setItem('pinnedRoutes', JSON.stringify(pinnedRoutes));
  }, [pinnedRoutes]);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("Got user location:", position.coords);
          setUserLocation({
            lat: position.coords.latitude,
            lon: position.coords.longitude
          });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
    }
  };


  const calculateDistance = useCallback((coord1, coord2) => {
    const R = 6371; // Earth's radius in km
    const dLat = (coord2.lat - coord1.lat) * Math.PI / 180;
    const dLon = (coord2.lon - coord1.lon) * Math.PI / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(coord1.lat * Math.PI / 180) * Math.cos(coord2.lat * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  }, []);

  const sortFerriesByLocation = useCallback((location = null) => {
    const now = new Date();

    const getNextDepartures = (ferry) => {
      return ferry.schedule.departures
        .map(dep => {
          const depTime = new Date(now.toDateString() + ' ' + (typeof dep === 'string' ? dep : dep.time));
          return { dep, depTime };
        })
        .filter(({ depTime }) => depTime > now)
        .sort((a, b) => a.depTime - b.depTime)
        .slice(0, 2);
    };

    const calculateNextDepartureTime = (departures) => {
      if (departures.length === 0) return Infinity;
      return (departures[0].depTime.getTime() - now.getTime()) / 1000; // Return seconds until departure
    };

    let sortedFerries = [...ferrySchedule].map(ferry => {
      const nextDepartures = getNextDepartures(ferry);
      return {
        ...ferry,
        nextDepartures,
        secondsUntilDeparture: calculateNextDepartureTime(nextDepartures)
      };
    });

    // Always sort by seconds until departure first
    sortedFerries.sort((a, b) => a.secondsUntilDeparture - b.secondsUntilDeparture);

    // Then, if showNearestFerryFirst is enabled and we have a location, sort by distance
    if (location && settings.showNearestFerryFirst) {
      sortedFerries.sort((a, b) => {
        const distA = calculateDistance(location, a.departureLocation.coordinates);
        const distB = calculateDistance(location, b.departureLocation.coordinates);
        return distA - distB;
      });
    }

    const mappedFerries = sortedFerries.map((ferry, index) => ({
      ...ferry,
      hasDeparturesLeft: ferry.secondsUntilDeparture !== Infinity,
      isMostNearby: location && settings.showNearestFerryFirst && index === 0,
      isMostNearbyAvailable: location && settings.showNearestFerryFirst && ferry.secondsUntilDeparture !== Infinity && index === 0
    }));

    setSortedFerries(mappedFerries);
    // scheduleNextSort();
  }, [calculateDistance, settings.showNearestFerryFirst]);

  useEffect(() => {
    if (userLocation) {
      sortFerriesByLocation(userLocation);
    } else {
      sortFerriesByLocation();
    }
    setOrderKey(uuidv4());
  }, [userLocation, sortFerriesByLocation]);

  useEffect(() => {
    sortFerriesByLocation();
    return () => {
      // if (sortTimeoutRef.current) {
      //   clearTimeout(sortTimeoutRef.current);
      // }
    };
  }, [sortFerriesByLocation]);

  // useEffect(() => {
  //   let intervalId;
  //   if (settings.autoUpdateLocation) {
  //     intervalId = setInterval(() => {
  //       getUserLocation();
  //     }, 5000);
  //   }
  //   return () => clearInterval(intervalId);
  // }, [settings.autoUpdateLocation]);

  const handlePin = (id) => {
    setPinnedRoutes(prevPinned =>
      prevPinned.includes(id)
        ? prevPinned.filter(routeId => routeId !== id)
        : [...prevPinned, id]
    );
  };

  const handleAddressSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(customAddress)}`);
      const data = await response.json();
      if (data && data[0]) {
        const location = { lat: parseFloat(data[0].lat), lon: parseFloat(data[0].lon) };
        setUserLocation(location);
        sortFerriesByLocation(location);
      } else {
        alert('Address not found. Please try a different address.');
      }
    } catch (error) {
      console.error('Error geocoding address:', error);
      alert('Error finding address. Please try again.');
    }
  };

  const handleRefresh = useCallback(() => {
    sortFerriesByLocation(userLocation);
    setOrderKey(uuidv4());
    setNeedsRefresh(false);
  }, [sortFerriesByLocation, userLocation]);

  useEffect(() => {
    const checkForRefresh = () => {
      const now = new Date();
      sortedFerries.forEach(ferry => {
        ferry.schedule.departures.forEach(dep => {
          const depTime = new Date(now.toDateString() + ' ' + (typeof dep === 'string' ? dep : dep.time));
          const timeDiff = (depTime.getTime() - now.getTime()) / 1000;
          if (timeDiff <= 1 && timeDiff > 0) {
            setNeedsRefresh(true);
          }
        });
      });
    };

    const intervalId = setInterval(checkForRefresh, 1000);

    return () => clearInterval(intervalId);
  }, [sortedFerries]);

  useEffect(() => {
    if (needsRefresh) {
      handleRefresh();
    }
  }, [needsRefresh, handleRefresh]);

  // Sort ferries: pinned first, then by distance
  const finalSortedFerries = sortedFerries.sort((a, b) => {
    if (pinnedRoutes.includes(a.id) && !pinnedRoutes.includes(b.id)) return -1;
    if (!pinnedRoutes.includes(a.id) && pinnedRoutes.includes(b.id)) return 1;
    return 0;
  });

  return (
    <ThemeProvider>
      <div className="min-h-screen text-gray-900 dark:text-gray-100 transition-colors duration-300">
        <div className="container mx-auto p-4">
          <div className="flex justify-between items-center mb-4">
            <Logo />
            <div>
              <button
                onClick={handleRefresh}
                className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 transition-colors duration-300 mr-2"
              >
                <RefreshCw size={20} />
              </button>
              <button
                onClick={() => getUserLocation()}
                className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 transition-colors duration-300 mr-2"
              >
                <RefreshCcwDotIcon size={20} />
              </button>
              <button
                onClick={() => setShowSettingsModal(true)}
                className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 transition-colors duration-300 mr-2"
              >
                <Settings size={20} />
              </button>
              <ThemeToggle />
            </div>
          </div>
          {settings.showLocationControls && (
            <div className="mb-4">
              <form onSubmit={handleAddressSubmit} className="flex items-center mb-2">
                <input
                  type="text"
                  value={customAddress}
                  onChange={(e) => setCustomAddress(e.target.value)}
                  placeholder="Enter an address"
                  className="flex-grow p-2 border rounded-l text-black"
                />
                <button type="submit" className="bg-blue-500 text-white p-2 rounded-r">
                  Confirm
                </button>
              </form>
            </div>
          )}
          <div className="space-y-8">
            {finalSortedFerries.map(ferry => (
              <FerryCard
                key={`${ferry.id}-${orderKey}`}
                ferry={ferry}
                isPinned={pinnedRoutes.includes(ferry.id)}
                onPin={handlePin}
                userLocation={userLocation}
                isClosest={ferry.isClosest}
              />
            ))}
          </div>
        </div>
        {showSettingsModal && (
          <SettingsModal onClose={() => setShowSettingsModal(false)} />
        )}
      </div>
    </ThemeProvider>
  );
};

export default App;
