import React, { useState, useEffect } from 'react';
import { Pin, PinOff, ChevronLeft, Calendar, Star, Map, Bike, Footprints, ChevronUp, ChevronDown } from 'lucide-react';
import { useSettings } from '../context/SettingsContext';
import { calculateDistance } from '../utils/distance';

// Test datax
const testFerry = {
  id: "test-ferry",
  route: "Test Route: Central Station - NDSM",
  schedule: {
    departures: [
      "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30",
      "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30",
      "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30",
      "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30",
      "22:00", "22:30", "23:00", "23:30"
    ]
  }
};

// FlipCard component
const FlipCard = ({ front, back, isFlipped, isPinned, isVisible }) => {
  const cardClass = `${isPinned ? 'flip-card mb-8 pinned-card' : 'flip-card mb-8'} ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500`;
  return (
    <div className={cardClass}>
      {!isFlipped && (
        <div className="flip-card-side flip-card-front">
          {front}
        </div>
      )}
      {isFlipped && (
        <div className="flip-card-side flip-card-back">
          {back}
        </div>
      )}
    </div>
  );
};
export const FerryCard = ({ ferry: propsFerry, isPinned, onPin, userLocation, isMostNearby, isMostNearbyAvailable }) => {
  const { settings } = useSettings();
  const ferry = propsFerry && propsFerry.schedule && Array.isArray(propsFerry.schedule.departures)
    ? propsFerry
    : testFerry;

  const [nextDepartures, setNextDepartures] = useState(['', '']);
  const [hasDeparturesLeft, setHasDeparturesLeft] = useState(true);
  const [bikeDistance, setBikeDistance] = useState(null);
  const [walkingDistance, setWalkingDistance] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, []);

  const toggleExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };


  useEffect(() => {
    if (userLocation && ferry.departureLocation && ferry.departureLocation.coordinates) {
      const distance = calculateDistance(
        userLocation.lat,
        userLocation.lon,
        ferry.departureLocation.coordinates.lat,
        ferry.departureLocation.coordinates.lon
      );
      const bikeTimeMinutes = Math.round(distance / 0.2); // Assuming average bike speed of 12 km/h (0.2 km/min)
      const walkTimeMinutes = Math.round(distance / 0.0833); // Assuming average walking speed of 5 km/h (0.0833 km/min)
      setBikeDistance(bikeTimeMinutes);
      setWalkingDistance(walkTimeMinutes);
    } else {
      setBikeDistance(null);
      setWalkingDistance(null);
    }
  }, [userLocation, ferry.departureLocation]);

  const handleNavigate = (e) => {
    e.stopPropagation();
    let mapsUrl;
    if (ferry.departureLocation && ferry.departureLocation.coordinates &&
      ferry.departureLocation.coordinates.lat && ferry.departureLocation.coordinates.lon) {
      mapsUrl = `https://www.google.com/maps/search/?api=1&query=${ferry.departureLocation.coordinates.lat},${ferry.departureLocation.coordinates.lon}`;
    } else {
      const address = encodeURIComponent(ferry.departureLocation?.name || ferry.route);
      mapsUrl = `https://www.google.com/maps/search/?api=1&query=${address}`;
    }
    window.open(mapsUrl, '_blank', 'noopener,noreferrer');
  };
  const [timesLeft, setTimesLeft] = useState(['', '']);
  const [highlights, setHighlights] = useState([false, false]);
  const [isFlipped, setIsFlipped] = useState(false);

  const getTime = (dep) => typeof dep === 'string' ? dep : dep.time;

  const formatTimeLeft = (diff) => {
    const hours = Math.floor(diff / 3600000);
    const minutes = Math.floor((diff % 3600000) / 60000);
    const seconds = Math.floor((diff % 60000) / 1000);

    if (hours >= 24) {
      return null; // Return null for departures more than 24 hours away
    } else if (hours > 0) {
      return `${hours}h ${minutes}m`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    } else {
      return `${seconds}s`;
    }
  };

  const formatDayAndTime = (date) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return `${days[date.getDay()]} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const updateNextDepartures = () => {
      const now = new Date();
      const currentDay = now.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
      const dayStrings = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      const currentDayString = dayStrings[currentDay];
      // Updated to check if the current day is included in the schedule.days array
      const shouldShowDepartures = ferry.schedule.days.includes(currentDayString);

      let upcomingDepartures = [];
      if (shouldShowDepartures) {
        upcomingDepartures = ferry.schedule.departures
          .map(dep => {
            const depTime = new Date(now.toDateString() + ' ' + getTime(dep));
            if (depTime < now) {
              depTime.setDate(depTime.getDate() + 1);
            }
            return { dep, depTime };
          })
          .sort((a, b) => a.depTime - b.depTime)
          .filter(({ depTime }) => depTime > now)
          .map(({ dep }) => dep);

        setHasDeparturesLeft(upcomingDepartures.length > 0);

        if (settings.showAllFerries || upcomingDepartures.length > 0) {
          upcomingDepartures = upcomingDepartures.slice(0, 2);
        }
      } else {
        setHasDeparturesLeft(false);
      }

      setNextDepartures(upcomingDepartures.map(getTime));


      const newTimesLeft = upcomingDepartures.map(dep => {
        let depTime = new Date(now.toDateString() + ' ' + getTime(dep));
        if (depTime < now) {
          depTime.setDate(depTime.getDate() + 1);
        }
        const diff = depTime - now;
        const timeLeft = formatTimeLeft(diff);
        return timeLeft === null ? formatDayAndTime(depTime) : timeLeft;
      });

      setTimesLeft(newTimesLeft);
      setHighlights(newTimesLeft.map(time => !time.includes('m') || (time.includes('m') && !time.includes('h') && parseInt(time) < 5)));
    };

    const timer = setInterval(updateNextDepartures, 1000);
    return () => clearInterval(timer);
  }, [ferry.schedule, settings.showAllFerries]);

  const getBackgroundClass = () => isPinned ? 'bg-yellow-50/90 dark:bg-yellow-900/90' : 'bg-white dark:bg-gray-900';
  const getBorderClass = () => isPinned ? 'border-l-4 border-yellow-400 dark:border-yellow-600' : '';
  const getAlarmClass = () => highlights[0] ? 'alarm-animation' : '';
  const frontContentClass = `h-full ${getBackgroundClass()} shadow-lg rounded-lg p-4 transition-all duration-300 ${getBorderClass()} ${getAlarmClass()}`;

  const frontContent = (
    <div className={frontContentClass}>
      <div className="flex justify-between items-start">
        <div className="flex flex-col">
          <h2 className="text-lg font-bold text-gray-800 dark:text-gray-200 mb-1">
            {ferry.route}
            {isMostNearby && (
              <span className="ml-2 px-2 py-1 bg-green-500 text-white text-xs rounded-full">
                Most Nearby
              </span>
            )}
            {isMostNearbyAvailable && !isMostNearby && (
              <span className="ml-2 px-2 py-1 bg-blue-500 text-white text-xs rounded-full">
                Most Nearby Available
              </span>
            )}
          </h2>
          <div className="flex items-center text-sm text-gray-500 dark:text-gray-400 space-x-4">
            {settings.showBikeDistance && bikeDistance !== null && (
              <div className="flex items-center">
                <Bike size={14} className="mr-1" />
                <span>{bikeDistance} min</span>
              </div>
            )}
            {settings.showWalkingDistance && walkingDistance !== null && (
              <div className="flex items-center">
                <Footprints size={14} className="mr-1" />
                <span>{walkingDistance} min</span>
              </div>
            )}
            {isPinned && <Star size={14} className="text-yellow-500" />}
          </div>
        </div>
        <div className={`text-2xl font-semibold ${highlights[0] ? 'text-red-500 dark:text-red-400' : 'text-gray-600 dark:text-gray-400'}`}>
          {timesLeft[0]}
        </div>
      </div>
      <div className="flex justify-between items-center mt-4">
        <span className="text-2xl font-bold text-gray-800 dark:text-gray-200">{nextDepartures[0]}</span>
        <button
          onClick={toggleExpand}
          className="text-blue-500 hover:text-blue-600 dark:text-gray-400 dark:hover:text-gray-200 ml-2"
        >
          {isExpanded ? <ChevronUp size={28} /> : <ChevronDown size={28} />}
        </button>
      </div>
      {isExpanded && (
        <div className="mt-6 -mx-4 -mb-4 pt-6 border-t border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 rounded-b-lg p-4">
          {nextDepartures[1] && (
            <div className="flex justify-between items-center mb-6">
              <span className="text-gray-700 dark:text-gray-300">{nextDepartures[1]}</span>
              <span className={`${highlights[1] ? 'text-red-500 dark:text-red-400' : 'text-gray-600 dark:text-gray-400'}`}>
                {timesLeft[1]}
              </span>
            </div>
          )}
          <div className="flex justify-around mt-4">
            <button onClick={handleNavigate} className="text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300">
              <Map size={24} />
            </button>
            <button onClick={(e) => { e.stopPropagation(); setIsFlipped(true); }} className="text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300">
              <Calendar size={24} />
            </button>
            <button onClick={(e) => { e.stopPropagation(); onPin(ferry.id); }} className="text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300">
              {isPinned ? <PinOff size={24} /> : <Pin size={24} />}
            </button>
          </div>
        </div>
      )}
    </div>
  );


  const backContentClass = `h-full ${isPinned ? 'bg-yellow-50/90 dark:bg-yellow-900/90' : 'bg-white dark:bg-gray-900'} shadow-lg rounded-lg p-6 transition-all duration-300 overflow-y-auto ${isPinned ? 'border-l-4 border-yellow-400 dark:border-yellow-600' : ''}`;

  const backContent = (
    <div className={backContentClass}>
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-semibold flex items-center">
          {isPinned && <Star size={20} className="text-yellow-500 mr-2" />}
          {ferry.route}
        </h2>
        <div className="flex items-center space-x-2">
          <button onClick={handleNavigate} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
            <Map size={20} />
          </button>
          <button onClick={(e) => { e.stopPropagation(); setIsFlipped(false); }} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
            <ChevronLeft size={20} />
          </button>
        </div>
      </div>
      <div className="text-sm">
        {ferry.schedule.departures.map((dep, index) => (
          <span key={index} className="inline-block mr-2 mb-1">
            {typeof dep === 'string' ? dep : `${dep.time}${dep.days ? ` (${dep.days.join(', ')})` : ''}`}
          </span>
        ))}
      </div>
    </div>
  );

  if (!settings.showAllFerries && !hasDeparturesLeft) {
    return null;
  }

  return <FlipCard isPinned={isPinned} front={frontContent} back={backContent} isFlipped={isFlipped} isVisible={isVisible} />;
};
