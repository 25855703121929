import React from 'react';
import { useSettings } from '../context/SettingsContext';

export const SettingsModal = ({ onClose }) => {
  const { settings, updateSettings } = useSettings();

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg">
        <h2 className="text-xl font-bold mb-4">Settings</h2>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={settings.showNearestFerryFirst}
              onChange={(e) => {
                const newValue = e.target.checked;
                updateSettings({ 
                  showNearestFerryFirst: newValue,
                  showClosestFerryFirst: newValue ? false : settings.showClosestFerryFirst
                });
              }}
              className="mr-2"
            />
            Show nearest ferry first
          </label>
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={settings.showNextDepartingFerryFirst}
              onChange={(e) => {
                const newValue = e.target.checked;
                updateSettings({ 
                  showNextDepartingFerryFirst: newValue,
                  showNearestFerryFirst: newValue ? false : settings.showNearestFerryFirst
                });
              }}
              className="mr-2"
            />
            Show next first departing ferry first
          </label>
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={settings.showLocationControls}
              onChange={(e) => updateSettings({ showLocationControls: e.target.checked })}
              className="mr-2"
            />
            Show location controls
          </label>
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={settings.showAllFerries}
              onChange={(e) => updateSettings({ showAllFerries: e.target.checked })}
              className="mr-2"
            />
            Show ferries that won't depart today
          </label>
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={settings.showBikeDistance}
              onChange={(e) => updateSettings({ showBikeDistance: e.target.checked })}
              className="mr-2"
            />
            Show estimated biking distance
          </label>
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={settings.showWalkingDistance}
              onChange={(e) => updateSettings({ showWalkingDistance: e.target.checked })}
              className="mr-2"
            />
            Show estimated walking distance
          </label>
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={settings.useSystemTheme}
              onChange={(e) => updateSettings({ useSystemTheme: e.target.checked })}
              className="mr-2"
            />
            Use system theme (light/dark mode)
          </label>
        </div>
        <button
          onClick={onClose}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};
