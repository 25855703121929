export const ferrySchedule = [
    {
        "id": 1,
        "route": "F1: Zamenhofstraat - Azartplein",
        "departureLocation": {
            "name": "Zamenhofstraat",
            "coordinates": { "lat": 52.3839, "lon": 4.9333 }
        },
        "arrivalLocation": {
            "name": "Azartplein",
            "coordinates": { "lat": 52.3769, "lon": 4.9368 }
        },
        "schedule": {
            type: "All week",
            days: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
            // "validFrom": "2024-06-13",
            // "validUntil": "2024-09-10",
            "departures": [
                "06:20", "06:40", "07:00", "07:20", "07:40", "08:00", "08:20", "08:40",
                "09:00", "09:20", "09:40", "10:00", "10:20", "10:40", "11:00", "11:20",
                "11:40", "12:00", "12:20", "12:40", "13:00", "13:20", "13:40", "14:00",
                "14:20", "14:40", "15:00", "15:20", "15:40", "16:00", "16:20", "16:40",
                "17:00", "17:20", "17:40", "18:00", "18:20", "18:40", "19:00", "19:20",
                "19:40", "20:00", "20:20", "20:40", "21:00", "21:20", "21:40", "22:00",
                "22:20"
            ]
        },
    },
    {
        "id": 10,
        "route": "F1: Azartplein - Zamenhofstraat",
        "departureLocation": {
            "name": "Azartplein",
            "coordinates": { "lat": 52.3769, "lon": 4.9368 }
        },
        "arrivalLocation": {
            "name": "Zamenhofstraat",
            "coordinates": { "lat": 52.3839, "lon": 4.9333 }
        },
        "schedule": {
            type: "All week",
            days: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
            // "validFrom": "2024-06-13",
            // "validUntil": "2024-09-10",
            "departures": [
                "06:30", "06:50", "07:10", "07:30", "07:50", "08:10", "08:30", "08:50",
                "09:10", "09:30", "09:50", "10:10", "10:30", "10:50", "11:10", "11:30",
                "11:50", "12:10", "12:30", "12:50", "13:10", "13:30", "13:50", "14:10",
                "14:30", "14:50", "15:10", "15:30", "15:50", "16:10", "16:30", "16:50",
                "17:10", "17:30", "17:50", "18:10", "18:30", "18:50", "19:10", "19:30",
                "19:50", "20:10", "20:30", "20:50", "21:10", "21:30", "21:50", "22:10",
                "22:30"
            ]
        },
    },
    {
        "id": 2,
        "route": "F2: IJplein - CS",
        "departureLocation": {
            "name": "IJplein",
            "coordinates": { "lat": 52.3821, "lon": 4.9075 }
        },
        "arrivalLocation": {
            "name": "CS",
            "coordinates": { "lat": 52.3791, "lon": 4.9006 }
        },
        "schedule": {
            "type": "weekday",
            "days": ["mon", "tue", "wed", "thu", "fri"],
            "departures": [
                "06:26", "06:32", "06:38", "06:44", "06:50", "06:56", "07:02", "07:08", "07:14", "07:20",
                "07:32", "07:38", "07:44", "07:50", "07:56", "08:02", "08:08", "08:14", "08:20", "08:26",
                "08:38", "08:44", "08:50", "08:56", "09:02", "09:08", "09:14", "09:20", "09:26", "09:32",
                "09:44", "09:50", "09:56", "10:02", "10:08", "10:14", "10:20", "10:26", "10:32", "10:38",
                "10:50", "10:56", "11:02", "11:08", "11:14", "11:20", "11:26", "11:32", "11:38", "11:44",
                "11:56", "12:02", "12:08", "12:14", "12:20", "12:26", "12:32", "12:38", "12:44", "12:50",
                "13:02", "13:08", "13:14", "13:20", "13:26", "13:32", "13:38", "13:44", "13:50", "13:56",
                "14:08", "14:14", "14:20", "14:26", "14:32", "14:38", "14:44", "14:50", "14:56", "15:02",
                "15:14", "15:20", "15:26", "15:32", "15:38", "15:44", "15:50", "15:56", "16:02", "16:08",
                "16:20", "16:26", "16:32", "16:38", "16:44", "16:50", "16:56", "17:02", "17:08", "17:14",
                "17:26", "17:32", "17:38", "17:44", "17:50", "17:56", "18:02", "18:08", "18:14", "18:20",
                "18:32", "18:38", "18:44", "18:50", "18:56", "19:02", "19:08", "19:14", "19:20", "19:26",
                "19:38", "19:44", "19:50", "19:56", "20:02", "20:08", "20:14", "20:20", "20:26", "20:32",
                "20:44", "20:50", "21:02", "21:14", "21:26", "21:38", "21:50", "22:02", "22:14", "22:26",
                "22:50", "23:02", "23:14", "23:26", "23:38", "23:50"
            ]
        }
    },
    {
        "id": 20,
        "route": "F2: IJplein - CS",
        "departureLocation": {
            "name": "IJplein",
            "coordinates": { "lat": 52.3821, "lon": 4.9075 }
        },
        "arrivalLocation": {
            "name": "CS",
            "coordinates": { "lat": 52.3791, "lon": 4.9006 }
        },
        "schedule": {
            "type": "saturday",
            "days": ["sat"],
            "departures": [
                "06:26", "06:38", "06:50", "07:02", "07:14", "07:26", "07:38",
                "08:26", "08:50", "09:02", "09:14", "09:26", "09:38", "09:50",
                "10:50", "11:02", "11:08", "11:14", "11:20", "11:26", "11:32",
                "12:02", "12:08", "12:14", "12:20", "12:26", "12:32", "12:38",
                "13:08", "13:14", "13:20", "13:26", "13:32", "13:38", "13:44",
                "14:14", "14:20", "14:26", "14:32", "14:38", "14:44", "14:50",
                "15:20", "15:26", "15:32", "15:38", "15:44", "15:50", "15:56",
                "16:26", "16:32", "16:38", "16:44", "16:50", "16:56", "17:02",
                "17:32", "17:38", "17:44", "17:50", "17:56", "18:02", "18:14",
                "18:44", "18:50", "18:56", "19:02", "19:08", "19:14", "19:20",
                "19:50", "19:56", "20:02", "20:08", "20:14", "20:20", "20:26",
                "21:26", "21:38", "21:50", "22:02", "22:14", "22:26", "22:38",
                "23:38", "23:50"
            ]
        }
    },
    {
        "id": 200,
        "route": "F2: IJplein - CS",
        "departureLocation": {
            "name": "IJplein",
            "coordinates": { "lat": 52.3821, "lon": 4.9075 }
        },
        "arrivalLocation": {
            "name": "CS",
            "coordinates": { "lat": 52.3791, "lon": 4.9006 }
        },
        "schedule": {
            "type": "sunday",
            "days": ["sun"],
            "departures": [
                "09:02", "09:14", "09:26", "09:38", "09:50", "10:02", "10:14",
                "11:08", "11:14", "11:20", "11:26", "11:32", "11:38", "11:44",
                "12:14", "12:20", "12:26", "12:32", "12:38", "12:44", "12:50",
                "13:20", "13:26", "13:32", "13:38", "13:44", "13:50", "13:56",
                "14:26", "14:32", "14:38", "14:44", "14:50", "14:56", "15:02",
                "15:32", "15:38", "15:44", "15:50", "15:56", "16:02", "16:08",
                "16:38", "16:44", "16:50", "16:56", "17:02", "17:08", "17:14",
                "17:44", "17:50", "17:56", "18:02", "18:14", "18:26", "18:38",
                "19:38", "19:50", "20:02", "20:14", "20:26", "20:38", "20:50",
                "21:50", "22:02", "22:14", "22:26", "22:38", "22:50", "23:02"
            ]
        }
    },
    {
        "id": 3,
        "route": "F3: Buiksloterweg - CS",
        "departureLocation": {
            "name": "Buiksloterweg",
            "coordinates": { "lat": 52.3837, "lon": 4.9020 }
        },
        "arrivalLocation": {
            "name": "CS",
            "coordinates": { "lat": 52.3791, "lon": 4.9006 }
        },
        "schedule": {
            type: "All week",
            days: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
            // "validFrom": "2024-06-17",
            // "validUntil": "2024-09-09",
            "departures": [
                "00:04", "00:16", "00:28", "00:40", "00:52", "01:04", "01:16", "01:28", "01:40", "01:52",
                "02:04", "02:16", "02:28", "02:40", "02:52", "03:04", "03:18", "03:30", "03:42", "03:54",
                "04:06", "04:20", "04:32", "04:44", "04:56", "05:08", "05:22", "05:34", "05:46", "05:58",
                "06:10", "06:22", "06:34", "06:40", "06:46", "06:52", "06:58", "07:04", "07:10", "07:16",
                "07:22", "07:28", "07:32", "07:36", "07:40", "07:44", "07:48", "07:52", "07:56", "08:00", "08:04",
                "08:08", "08:12", "08:16", "08:20", "08:24", "08:28", "08:32", "08:36", "08:40", "08:44", "08:48",
                "08:52", "08:56", "09:00", "09:04", "09:08", "09:12", "09:16", "09:20", "09:24", "09:28", "09:32",
                "09:36", "09:40", "09:44", "09:48", "09:52", "09:56", "10:00", "10:04", "10:08", "10:12", "10:16",
                "10:20", "10:24", "10:28", "10:32", "10:36", "10:40", "10:44", "10:48", "10:52", "10:56", "11:00",
                "11:04", "11:08", "11:12", "11:16", "11:20", "11:24", "11:28", "11:32", "11:36", "11:40", "11:44",
                "11:48", "11:52", "11:56", "12:00", "12:04", "12:08", "12:12", "12:16", "12:20", "12:24", "12:28",
                "12:32", "12:36", "12:40", "12:44", "12:48", "12:52", "12:56", "13:00", "13:04", "13:08", "13:12",
                "13:16", "13:20", "13:24", "13:28", "13:32", "13:36", "13:40", "13:44", "13:48", "13:52", "13:56",
                "14:00", "14:04", "14:08", "14:12", "14:16", "14:20", "14:24", "14:28", "14:32", "14:36", "14:40",
                "14:44", "14:48", "14:52", "14:56", "15:00", "15:04", "15:08", "15:12", "15:16", "15:20", "15:24",
                "15:28", "15:32", "15:36", "15:40", "15:44", "15:48", "15:52", "15:56", "16:00", "16:04", "16:08",
                "16:12", "16:16", "16:20", "16:24", "16:28", "16:32", "16:36", "16:40", "16:44", "16:48", "16:52",
                "16:56", "17:00", "17:04", "17:08", "17:12", "17:16", "17:20", "17:24", "17:28", "17:32", "17:36",
                "17:40", "17:44", "17:48", "17:52", "17:56", "18:00", "18:04", "18:08", "18:12", "18:16", "18:20",
                "18:24", "18:28", "18:32", "18:36", "18:40", "18:44", "18:48", "18:52", "18:56", "19:00", "19:04",
                "19:08", "19:12", "19:16", "19:20", "19:24", "19:28", "19:32", "19:36", "19:40", "19:44", "19:48",
                "19:52", "19:56", "20:04", "20:10", "20:16", "20:22", "20:28", "20:34", "20:40", "20:46", "20:52",
                "20:58", "21:04", "21:10", "21:16", "21:22", "21:28", "21:34", "21:40", "21:46", "21:52", "21:58",
                "22:04", "22:10", "22:16", "22:22", "22:28", "22:34", "22:40", "22:46", "22:52", "22:58", "23:04",
                "23:10", "23:16", "23:22", "23:28", "23:34", "23:40", "23:46", "23:52"
            ]
        }
    },
    {
        "id": 30,
        "route": "F3: CS - Buiksloterweg",
        "departureLocation": {
            "name": "CS",
            "coordinates": { "lat": 52.3791, "lon": 4.9006 }
        },
        "arrivalLocation": {
            "name": "Buiksloterweg",
            "coordinates": { "lat": 52.3837, "lon": 4.9020 }
        },
        "schedule": {
            type: "All week",
            days: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
            // "type": "sunday",
            // "days": ["sun"],
            // "validFrom": "2024-06-16",
            // "validUntil": "2024-09-08",
            "departures": [
                "00:00", "00:12",
                "00:24", "00:36", "00:48", "01:00", "01:12", "01:24", "01:36", "01:48", "02:00", "02:12", "02:24",
                "02:36", "02:48", "03:00", "03:12", "03:24", "03:36", "03:48", "04:00", "04:14", "04:26", "04:38",
                "04:50", "05:02", "05:16", "05:28", "05:40", "05:52", "06:04", "06:16", "06:28", "06:34", "06:40",
                "06:46", "06:52", "06:58", "07:04", "07:10", "07:16",
                "07:22", "07:28", "07:34", "07:40", "07:46", "07:52", "07:58", "08:04", "08:10", "08:16", "08:22",
                "08:28", "08:34", "08:40", "08:46", "08:52", "08:58", "09:04", "09:10", "09:16", "09:22", "09:28",
                "09:34", "09:40", "09:46", "09:52", "09:58", "10:04", "10:10", "10:16", "10:22", "10:28", "10:34",
                "10:40", "10:46", "10:52", "10:58", "11:04", "11:10", "11:16", "11:22", "11:28", "11:34", "11:40",
                "11:46", "11:52", "11:58", "12:02", "12:06", "12:10", "12:14", "12:18", "12:22", "12:26", "12:30",
                "12:34", "12:38", "12:42", "12:46", "12:50", "12:54", "12:58", "13:02", "13:06", "13:10", "13:14",
                "13:18", "13:22", "13:26", "13:30", "13:34", "13:38", "13:42", "13:46", "13:50", "13:54", "13:58",
                "14:02", "14:06", "14:10", "14:14", "14:18", "14:22", "14:26", "14:30", "14:34", "14:38", "14:42",
                "14:46", "14:50", "14:54", "14:58", "15:02", "15:06", "15:10", "15:14", "15:18", "15:22", "15:26",
                "15:30", "15:34", "15:38", "15:42", "15:46", "15:50", "15:54", "15:58", "16:02", "16:06", "16:10",
                "16:14", "16:18", "16:22", "16:26", "16:30", "16:34", "16:38", "16:42", "16:46", "16:50", "16:54",
                "16:58", "17:02", "17:06", "17:10", "17:14", "17:18", "17:22", "17:26", "17:30", "17:34", "17:38",
                "17:42", "17:46", "17:50", "17:54", "17:58", "18:02", "18:06", "18:10", "18:14", "18:18", "18:22",
                "18:26", "18:30", "18:34", "18:38", "18:42", "18:46", "18:50", "18:54", "18:58", "19:02", "19:06",
                "19:10", "19:14", "19:18", "19:22", "19:26", "19:30", "19:34", "19:38", "19:42", "19:46", "19:50",
                "19:54", "19:58", "20:02", "20:06", "20:12", "20:18", "20:24", "20:30", "20:36", "20:42", "20:48",
                "20:54", "21:00", "21:06", "21:12", "21:18", "21:24", "21:30", "21:36", "21:42", "21:48", "21:54",
                "22:00", "22:06", "22:12", "22:18", "22:24", "22:30", "22:36", "22:42", "22:48", "22:54", "23:00",
                "23:06", "23:12", "23:18", "23:24", "23:30", "23:36", "23:42", "23:48", "23:54",
            ]
        }
    },
    {
        id: 4,
        route: "F4: CS - NDSM",
        "departureLocation": {
            "name": "CS",
            "coordinates": { "lat": 52.3780, "lon": 4.9003 }
        },
        "arrivalLocation": {
            "name": "NDSM-wharf",
            "coordinates": { "lat": 52.4030, "lon": 4.8880 }
        },
        schedule:
        {
            type: "All week",
            days: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
            departures: [
                { time: "06:30", days: ['mon', 'tue', 'wed', 'thu', 'fr'] },
                "07:00", "07:30", "08:00", "08:30", "09:00", "09:15", "09:30", "09:45", "10:00", "10:10", "10:20", "10:30",
                "10:40", "10:50", "11:00", "11:10", "11:20", "11:30", "11:40", "11:50", "12:00", "12:10", "12:20", "12:30",
                "12:40", "12:50", "13:00", "13:10", "13:20", "13:30", "13:40", "13:50", "14:00", "14:10", "14:20", "14:30",
                "14:40", "14:50", "15:00", "15:10", "15:20", "15:30", "15:40", "15:50", "16:00", "16:10", "16:20", "16:30",
                "16:40", "16:50", "17:00", "17:10", "17:20", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00",
                "19:15", "19:30", "19:45", "20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45", "22:15",
                "22:45", "23:15", "23:45", "00:15", "00:45", "01:15", "01:45",
                { time: "02:15", days: ['fri', 'sat'] },
                { time: "02:45", days: ['fri', 'sat'] },
                { time: "03:15", days: ['fri', 'sat'] },
            ]
        }

    },
    {
        id: 5,
        route: "F4: NDSM - CS",
        "departureLocation": {
            "name": "NDSM-wharf",
            "coordinates": { "lat": 52.4030, "lon": 4.8880 }
        },
        "arrivalLocation": {
            "name": "CS",
            "coordinates": { "lat": 52.3780, "lon": 4.9003 }
        },
        schedule:
        {
            type: "All week",
            days: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
            departures: [
                { time: "06:45", days: ['mon', 'tue', 'wed', 'thu', 'fri'] },
                "07:15", "07:45", "08:00", "08:15", "08:30", "08:45", "09:00",
                "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00",
                "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00",
                "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45", "15:00",
                "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00",
                "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00",
                "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00",
                "23:30", "00:00", "00:30", "01:30", "02:00",
                { time: "02:30", days: ['fri', 'sat'] },
                { time: "03:00", days: ['fri', 'sat'] },
                { time: "03:30", days: ['fri', 'sat'] },
            ]
        }

    },
    // {
    //     "route": "F5: CS - NDSM-wharf via Pontsteiger (night service)",
    //     "departureLocation": {
    //         "name": "CS",
    //         "coordinates": { "lat": 52.3780, "lon": 4.9003 }
    //     },
    //     "arrivalLocation": {
    //         "name": "NDSM-wharf",
    //         "coordinates": { "lat": 52.4030, "lon": 4.8880 }
    //     }
    // },
    // {
    //   id: 6,
    //   route: "CS - NDSM via Pontsteiger (F5)",
    //   schedule: [
    //     {
    //       type: "weekday",
    //       days: ["mon", "tue", "wed", "thu", "fri"],
    //       departures: ["00:23", "00:53", "01:23", "01:53"]
    //     },
    //     {
    //       type: "weekend",
    //       days: ["sat", "sun"],
    //       departures: ["00:23", "00:53", "01:23", "01:53", "02:23", "02:53"]
    //     }
    //   ]
    // },
    // {
    //   id: 7,
    //   route: "NDSM via Pontsteiger - CS (F5)",
    //   schedule: [
    //     {
    //       type: "weekday",
    //       days: ["mon", "tue", "wed", "thu", "fri"],
    //       departures: ["00:38", "01:08", "01:38", "02:08"]
    //     },
    //     {
    //       type: "weekend",
    //       days: ["sat", "sun"],
    //       departures: ["00:38", "01:08", "01:38", "02:08", "02:38", "03:08"]
    //     }
    //   ]
    // },
    {
        id: 8,
        route: "F6: Pontsteiger - Distelweg",
        "departureLocation": {
            "name": "Pontsteiger",
            "coordinates": { "lat": 52.3910, "lon": 4.8830 }
        },
        "arrivalLocation": {
            "name": "Distelweg",
            "coordinates": { "lat": 52.3998, "lon": 4.8920 }
        },
        schedule:
        {
            type: "weekday",
            days: ["mon", "tue", "wed", "thu", "fri"],
            departures: [
                "06:30", "06:45", "07:00", "07:15", "07:30", "07:45", "08:00", "08:15", "08:30", "08:45",
                "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45",
                "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45",
                "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45",
                "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00", "20:15", "20:30", "20:45",
                "21:00", "21:15", "21:30", "21:45", "22:00", "22:15", "22:30", "22:45"
            ]
        }
    },
    {
        id: 9,
        route: "F6: Distelweg - Pontsteiger",
        departureLocation: {
            name: "Distelweg",
            coordinates: { lat: 52.3998, lon: 4.8920 }
        },
        arrivalLocation: {
            name: "Pontsteiger",
            coordinates: { lat: 52.3910, lon: 4.8830 }
        },
        schedule:
        {
            type: "weekday",
            days: ["mon", "tue", "wed", "thu", "fri"],
            departures: [
                "06:37", "06:52", "07:07", "07:22", "07:37", "07:52", "08:07", "08:22",
                "08:37", "08:52", "09:07", "09:22", "09:37", "09:52", "10:07", "10:22",
                "10:37", "10:52", "11:07", "11:22", "11:37", "11:52", "12:07", "12:22",
                "12:37", "12:52", "13:07", "13:22", "13:37", "13:52", "14:07", "14:22",
                "14:37", "14:52", "15:07", "15:22", "15:37", "15:52", "16:07", "16:22",
                "16:37", "16:52", "17:07", "17:22", "17:37", "17:52", "18:07", "18:22",
                "18:37", "18:52", "19:07", "19:22", "19:37", "19:52", "20:07", "20:22",
                "20:37", "20:52", "21:07", "21:22", "21:37", "21:52", "22:07", "22:22",
                "22:37", "22:52"
            ]
        },
    }
];
